<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  components: { },

  data: () => ({
    connection: null
  }),
  created: function () {
    /*
    this.connection = new WebSocket('ws://37.247.103.214:12346')
    console.log(this.connection)
    this.connection.onmessage = function (event) {
      console.log(event)
    }
    this.connection.onopen = function (event) {
      console.log(event)
      console.log('Successfully connected to the echo websocket server...')
    }
    */
  }
}
</script>