// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

const myCustomLightTheme = {
  light: true,
  colors: {
    primary: '#55334E',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  }
}

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    }
  }
})