<template>
  <v-app id="home" class="white">
    <v-overlay v-model="overlay" class="align-center justify-center">
      <v-progress-circular
        indeterminate
        size="64"
        color="#EF7D09"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="snackbar.show"
      right
      top
      timeout="2000"
      :color="snackbar.color">
      <v-icon color="white">{{snackbar.icon}}</v-icon> {{ snackbar.text }}
    </v-snackbar>
    <v-layout>
      <TopBar/>
      <v-main>
        <v-container fluid>
          <v-row>
            <v-expansion-panels>
              <v-expansion-panel
                title="Servant Password">
                <v-expansion-panel-text>
                  <v-form
                    ref="formChangeServPass"
                    lazy-validation
                    v-model="validChangeServPass"
                    @submit.prevent="ChangeServPass">
                    <v-text-field
                      label="Please Enter Servant Passwprd"
                      hint="Please enter servant password"
                      persistent-hint
                      required
                      v-model="ServPass"
                      clearable
                      :counter="4"
                      :rules="[rules.required, rules.name, rules.pass]"
                      class="pt-0 mt-4"
                      @keypress="onlyNumber"
                      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPass = !showPass"
                      :type="showPass ? 'text' : 'password'"
                      @keyup.enter="ChangeServPass"
                    ></v-text-field>
                  </v-form>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <br/>
          <v-row>
            <v-btn prepend-icon="mdi-reload" @click="ReloadServer">Reload Server</v-btn>
          </v-row>
        </v-container>
      </v-main>
    </v-layout>
  </v-app>
</template>

<script>
// @ is an alias to /src
import TopBar from '@/components/TopBar.vue'
import axios from 'axios'

export default {
  name: 'home',
  components: {
    TopBar
  },
  data: () => ({
    overlay: false,
    snackbar: {
      show: false,
      text: '',
      color: 'success',
      icon: 'fas fa-check-circle'
    },
    showPass: false,
    rules: {
      required: value => !!value || 'This field is required.',
      name: [val => (val || '').length > 0 || 'This field is required'],
      pass: [val => (val || '').length === 4 || 'User Password must be 4 characters']
    },
    send: null,
    ServPass: '',
    validChangeServPass: true
  }),
  methods: {
    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if (keyCode < 48 || keyCode > 57) { // 46 is dot
        $event.preventDefault()
      }
    },
    loadSerPass () {
      this.overlay = true
      var UserID = this.$storage.getStorageSync("UserID")
      var AccID = this.$storage.getStorageSync("AccID")
      var SessionID = this.$storage.getStorageSync("SessionID")

      var data = '{ "AccID": ' + AccID + ', "UserID": ' + UserID + ', "SessionID": "' + SessionID + '", "Method": "GetServPass" }'
      this.$socket.send(data)
    },
    ChangeServPass () {
      this.overlay = true
      if (this.validChangeServPass && this.ServPass.length === 4) {
        var UserID = this.$storage.getStorageSync("UserID")
        var AccID = this.$storage.getStorageSync("AccID")
        var SessionID = this.$storage.getStorageSync("SessionID")
        var data = '{ "AccID": ' + AccID + ', "UserID": ' + UserID + ', "SessionID": "' + SessionID + '", "ServPass": "' + this.ServPass + '", "Method": "SetServPass" }'
        this.$socket.send(data)
      }
    },
    ReloadServer () {
      this.overlay = true
      var UserID = this.$storage.getStorageSync("UserID")
      var AccID = this.$storage.getStorageSync("AccID")
      var SessionID = this.$storage.getStorageSync("SessionID")
      var result = false
      const formData = new FormData()
      formData.append('UserID', UserID)
      formData.append('AccID', AccID)
      formData.append('SessionID', SessionID)
      axios.post('https://hopinlock.com/hopinlock/api/reload_server.php', formData)
        .then(function (response) {
          if (Number(response.data.ResultCode) === 1) {
            result = true
          }
        })
        .catch(function () {
          result = -1
        })
        .finally(() => {
          this.overlay = false
          if (result) {
            this.snackbar.text = 'The server restart request has been created successfully. Your transaction will be completed in 3-5 minutes.'
            this.snackbar.color = 'success'
            this.snackbar.icon = 'mdi-check-circle'
            this.snackbar.show = true
          } else {
            this.snackbar.text = 'Unknown Error. Please Try Again'
            this.snackbar.color = 'error'
            this.snackbar.icon = 'mdi-alert-circle'
            this.snackbar.show = true
          }
        })
    }
  },
  created: function () {
    let self = this
    this.$socket.onmessage = function (event) {
      let data = JSON.parse(event.data)
      console.log(data)
      let ResultCode = Number(data.ResultCode)
      if (ResultCode === 1) {
        let Method = data.Method
        if (Method === 'GetServPass') {
          self.ServPass = data.ServPass
          self.overlay = false
        } else if (Method === 'SetServPass') {
          self.overlay = false
          self.snackbar.text = 'The servant password has been successfully updated.'
          self.snackbar.color = 'success'
          self.snackbar.icon = 'fas fa-check-circle'
          self.snackbar.show = true
        }
      } else if (ResultCode === 9) {
        self.$storage.removeStorageSync("AccID")
        self.$storage.removeStorageSync("UserID")
        self.$storage.removeStorageSync("SessionID")
        self.$router.push('/')
      }
    }
    this.send = setInterval(()=>{
      if (self.$socket.readyState === 1) {
        self.loadSerPass()
        clearInterval(self.send)
      }
    }, 1000);
  }
}
</script>