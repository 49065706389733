import { createWebHistory, createRouter } from "vue-router";
import MyLogin from "@/views/Login.vue";
import MyHome from "@/views/Home.vue";
import MySettings from "@/views/Settings.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: MyLogin,
    meta: {
      title: 'Lock Management Panel'
    }
  },
  {
    path: "/home",
    name: "home",
    component: MyHome,
    meta: {
      title: 'Lock Management Panel'
    }
  },
  {
    path: "/settings",
    name: "settings",
    component: MySettings,
    meta: {
      title: 'Lock Management Panel'
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;