<template>
  <v-app id="home" class="white">
    <v-overlay v-model="overlay" class="align-center justify-center">
      <v-progress-circular
        indeterminate
        size="64"
        color="#EF7D09"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="snackbar.show"
      right
      top
      timeout="2000"
      :color="snackbar.color">
      <v-icon color="white">{{snackbar.icon}}</v-icon> {{ snackbar.text }}
    </v-snackbar>
    <v-dialog v-model="AddDialog" scrollable max-width="500px">
      <v-card>
        <v-card-title
          class="headline lighten-2"
          style="background: rgb(239, 125, 9); color: white;"
          primary-title>
          <v-icon large color="white" class="mr-2">fas fa-lock</v-icon> Reservation Lock ({{selectedLock.LockNo}})
        </v-card-title>
        <v-divider class="ma-0"></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="pa-0">
                <v-form
                  ref="formAdd"
                  lazy-validation
                  v-model="validAddLock"
                  @submit.prevent="DoAddReservation">
                  <v-text-field
                    label="Name"
                    hint="Please Enter Name"
                    persistent-hint
                    :rules="[rules.required, rules.name]"
                    required
                    v-model="AddReservation.name"
                    clearable></v-text-field>
                  <v-text-field
                    label="Phone"
                    hint="Please Enter Phone Number"
                    persistent-hint
                    :rules="[rules.required, rules.name]"
                    required
                    v-if="GelenAccId === 441"
                    v-model="AddReservation.phone"
                    clearable></v-text-field>
                  <v-row v-if="GelenAccId === 441">
                    <v-col cols="8">
                      <v-text-field
                        label="Duration"
                        hint="Please Enter Duration"
                        persistent-hint
                        :rules="[rules.required, rules.name]"
                        required
                        v-model="AddReservation.duration"
                        clearable></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        label=""
                        :items="['Minute', 'Hour', 'Day', 'Week', 'Month', 'Year']"
                        :rules="[rules.required, rules.name]"
                        required
                        v-model="AddReservation.durationType"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider class="ma-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="rgb(85, 51, 78)"
            text @click="AddDialog = false">
            Close
          </v-btn>
          <v-btn
            color="rgb(85, 51, 78)"
            text
            :disabled="!validAddLock"
            @submit="DoAddReservation"
            @click="DoAddReservation">Set Reservation</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout>
      <TopBar/>
      <v-main>
        <v-container fluid>
          <div class="d-flex justify-center align-center mb-2" v-if="GelenAccId > 0">
            <v-img max-width="256" :src="AccImgSrc"/>
          </div>
          <div class="d-flex justify-space-between align-center mb-6">
            <v-btn icon="mdi-arrow-left-bold" size="x-large" @click="prevZone"></v-btn>
            <h1 class="text-center title ffont-weight-black pl-2">{{selectZone.ZoneName}}</h1>
            <v-btn icon="mdi-arrow-right-bold" size="x-large" @click="nextZone"></v-btn>
          </div>
          <v-row dense>
            <v-col
              v-for="(lock, i) in selectZone.Locks"
              :key="i"
              cols="2"
            >
              <v-sheet
                :color="lockBgColor(lock)"
                class="d-flex align-center justify-start flex-wrap text-center mx-auto"
                rounded
                border
                height="120"
                style="cursor: pointer"
                @click="Yap(lock)"
              >
                <div class="w-100">
                  <div class="d-flex align-center justify-center flex-wrap text-center mx-auto">
                    <div>
                      <h4 class="text-h6 font-weight-black text-white">{{lock.LockNo}} <span v-if="lock.LockDesc != ''"> - {{lock.LockDesc}}</span></h4>
                      <p class="text-body-2 mb-4 text-white" v-if="lock.LockJunoState === 1" style="white-space: nowrap;">{{shortText(lock.LockResName)}}</p>
                      <p class="text-body-2 mb-4" v-else>&nbsp;</p>
                    </div>
                  </div>
                  <div class="d-flex justify-space-between w-100">
                    <v-icon icon="mdi-lock-outline" size="large" color="white" v-if="lock.LockState === 3"></v-icon>
                    <v-icon icon="mdi-lock-outline" size="large" color="white" v-else-if="lock.LockState === 2"></v-icon>
                    <v-icon icon="mdi-lock-open-variant-outline" size="large" color="white" v-else></v-icon>
                    <v-icon icon="mdi-refresh" size="large" color="white" @click="checkLockState(lock)" v-if="lock.LockJunoState === 2"></v-icon>
                    <div class="d-flex justify-center align-center w-100" v-if="GelenAccId === 441 && lock.LockJunoState == 1">
                      <v-icon icon="mdi-clock-alert mr-2" size="24" color="yellow"></v-icon>
                      <div>
                        <p class="text-yellow">{{ GetSaat(lock) }}</p>
                        <p class="text-yellow">{{ GetTarih(lock) }}</p>
                      </div>
                    </div>
                    <!--
                    <v-icon icon="mdi-link" size="large" color="white" v-if="lock.LockConnState === 1"></v-icon>
                    <v-icon icon="mdi-link-off" size="large" color="white" v-else></v-icon>
                    -->
                    
                    <!--
                    <v-icon icon="mdi-wifi" size="large" color="white" v-if="lock.LockConnState === 1"></v-icon>
                    <v-icon icon="mdi-wifi-off" size="large" color="white" v-else></v-icon>
                    -->
                  </div>
                </div>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-layout>
  </v-app>
</template>

<script>
// @ is an alias to /src
import TopBar from '@/components/TopBar.vue'
import { state,socket } from "@/socket"
import moment from 'moment'

export default {
  name: 'home',
  components: {
    TopBar
  },
  methods: {
    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if (keyCode < 48 || keyCode > 57) { // 46 is dot
        $event.preventDefault()
      }
    },
    lockBgColor (lock) {
      if(lock.LockJunoState === 0) {
        return 'green-darken-4'
      }
      else if(lock.LockJunoState === 2) {
        return 'yellow-darken-4'
      }
      else if(lock.LockJunoState === 1) {
        return 'red-darken-4'
      } else {
        return ''
      }
    },
    GetSaat(lock){
      var date = new Date(lock.StartResDateTime)
      console.log(moment(date, "HH:mm"))
      var dateStr = moment(date, "HH:mm").add(3,'hours')
      switch (lock.LockDuration) {
          case "Minute":
            dateStr = dateStr.add(lock.LockDurationVal,'minutes').format('HH:mm')
            break
          case "Hour":
            dateStr = dateStr.add(lock.LockDurationVal,'hours').format('HH:mm')
            break
          case "Day":
            dateStr = dateStr.add(lock.LockDurationVal,'days').format('HH:mm')
            break
          case "Week":
            dateStr = dateStr.add(lock.LockDurationVal,'weeks').format('HH:mm')
            break
          case "Month":
            dateStr = dateStr.add(lock.LockDurationVal,'days').format('HH:mm')
            break
          case "Year":
            dateStr = dateStr.add(lock.LockDurationVal,'years').format('HH:mm')
            break
      }
      console.log(dateStr)
      return dateStr
      //return lock.StartResDateTime
    },
    GetTarih(lock){
      var date = new Date(lock.StartResDateTime)
      var dateStr = moment(date, "DD.MM.YYYY").add(3,'hours')
      switch (lock.LockDuration) {
          case "Minute":
            dateStr = dateStr.add(lock.LockDurationVal,'minutes').format('DD.MM.YYYY')
            break
          case "Hour":
            dateStr = dateStr.add(lock.LockDurationVal,'hours').format('DD.MM.YYYY')
            break
          case "Day":
            dateStr = dateStr.add(lock.LockDurationVal,'days').format('DD.MM.YYYY')
            break
          case "Week":
            dateStr = dateStr.add(lock.LockDurationVal,'weeks').format('DD.MM.YYYY')
            break
          case "Month":
            dateStr = dateStr.add(lock.LockDurationVal,'days').format('DD.MM.YYYY')
            break
          case "Year":
            dateStr = dateStr.add(lock.LockDurationVal,'years').format('DD.MM.YYYY')
            break
      }
      return dateStr
      //return lock.StartResDateTime
    },
    Yap (lock) {
      this.selectedLock = lock
      if (lock.LockJunoState === 0) {
        if(lock.LockState != 3 && lock.LockState != 2) {
          this.snackbar.text = 'The lock must be in the closed position..'
          this.snackbar.color = 'error'
          this.snackbar.icon = 'fas fa-times-circle'
          this.snackbar.show = true
        } else {
          this.AddReservation.name = ""
          this.AddDialog = true
        }
      }
      else if(lock.LockJunoState === 1) {
        this.$swal({
          title: 'Cancel Reservation Lock',
          text: 'Cancel lock reservation Lock Number ' + lock.LockNo + '. Are you sure?',
          type: 'warning',
          confirmButtonText: 'YES',
          showCancelButton: true,
          cancelButtonText: 'NO',
          showCloseButton: true,
          showLoaderOnConfirm: true,
          confirmButtonColor: '#41b882',
          cancelButtonColor: '#ff7674'
        }).then((result) => {
          if (result.value) {
            this.overlay = true
            // var data = '{ "Command": "pin assign unlimited 4 1616 1" }'
            var UserID = this.$storage.getStorageSync("UserID")
            var AccID = this.$storage.getStorageSync("AccID")
            var SessionID = this.$storage.getStorageSync("SessionID")
            var data = '{ "AccID": ' + AccID + ', "UserID": ' + UserID + ', "SessionID": "' + SessionID + '", "LockID": ' + this.selectedLock.LockID + ', "ResID": ' + this.selectedLock.LockResID + ', "Method": "CancelReservation" }'
            socket.timeout(5000).emit("messageJuno", data, () => {
              console.log("ok")
            });
            // this.$socket.send(data);
          }
        })
      } /*
      else if(lock.LockJunoState === 1) {
        this.$swal({
          title: 'Prepare Lock',
          text: 'Lock number ' + lock.no + ' is brought to the appropriate position. Are you sure?',
          type: 'warning',
          confirmButtonText: 'YES',
          showCancelButton: true,
          cancelButtonText: 'NO',
          showCloseButton: true,
          showLoaderOnConfirm: true,
          confirmButtonColor: '#41b882',
          cancelButtonColor: '#ff7674'
        }).then((result) => {
          if (result.value) {
            this.selectedLock.LockJunoState = 0;
            var data = '{ "Command": "pin assign unlimited 4 ffff 1" }'
            this.$socket.send(data);
          }
        })
      }
      */
    },
    DoAddReservation () {
      if (this.validAddLock) {
        this.AddDialog = false
        this.overlay = true
        var UserID = this.$storage.getStorageSync("UserID")
        var AccID = this.$storage.getStorageSync("AccID")
        var SessionID = this.$storage.getStorageSync("SessionID")
        var ReservationName = this.AddReservation.name
        var ReservationPhone = "455555"
        var ReservationDuration = "1"
        if (this.AddReservation.phone !== '') {
          ReservationPhone = this.AddReservation.phone
        }
        if (this.AddReservation.duration !== '') {
          ReservationDuration = this.AddReservation.duration
        }
        var ReservationDurationType = this.AddReservation.durationType
        var data = '{ "AccID": ' + AccID + ', "UserID": ' + UserID + ', "SessionID": "' + SessionID + '", "LockID": ' + this.selectedLock.LockID + ', "UserNameSurname": "' + ReservationName + '", "PhoneNo": "' + ReservationPhone + '", "Duration": "' + ReservationDuration + '", "DurationType": "' + ReservationDurationType + '", "Method": "SetReservation" }'
        socket.timeout(5000).emit("messageJuno", data, () => {
          console.log("ok")
        });
        //this.$socket.send(data)
      }
    },
    loadLocks () {
      this.overlay = true
      var UserID = this.$storage.getStorageSync("UserID")
      var AccID = this.$storage.getStorageSync("AccID")
      var SessionID = this.$storage.getStorageSync("SessionID")

      var data = '{ "AccID": ' + AccID + ', "UserID": ' + UserID + ', "SessionID": "' + SessionID + '", "Method": "GetLocks" }'
      // this.$socket.send(data)
      socket.timeout(5000).emit("messageJuno", data, () => {
        console.log("ok")
      });
    },
    nextZone () {
      if(this.zones.length > 0) {
        this.selectIndex++
        if(this.selectIndex === this.zones.length){
          this.selectIndex = 0
        }
        this.selectZone = this.zones[this.selectIndex]
      }
    },
    prevZone () {
      if(this.zones.length > 0) {
        this.selectIndex--
        if(this.selectIndex === -1){
          this.selectIndex = this.zones.length - 1
        }
        this.selectZone = this.zones[this.selectIndex]
      }
    },
    shortText(val) {
      if(val.length > 25) {
        return val.substr(0, 25) + "..."
      }
      return val
    },
    checkLockState (lock) {
      this.selectedLock = lock
      this.overlay = true
      var UserID = this.$storage.getStorageSync("UserID")
      var AccID = this.$storage.getStorageSync("AccID")
      var SessionID = this.$storage.getStorageSync("SessionID")
      var data = '{ "AccID": ' + AccID + ', "UserID": ' + UserID + ', "SessionID": "' + SessionID + '", "LockID": ' + this.selectedLock.LockID + ', "Method": "CheckLockState" }'
      socket.timeout(5000).emit("messageJuno", data, () => {
        console.log("ok")
      });
    }
  },
  data: () => ({
    GelenAccId: 0,
    AccImgSrc: "",
    overlay: false,
    snackbar: {
      show: false,
      text: '',
      color: 'success',
      icon: 'fas fa-check-circle'
    },
    AddDialog: false,
    validAddLock: true,
    show1: false,
    rules: {
      required: value => !!value || 'This field is required.',
      name: [val => (val || '').length > 0 || 'This field is required'],
      master: [val => (val || '').length === 6 || 'Master Password must be 6 characters'],
      user: [val => (val || '').length === 4 || 'User Password must be 4 characters']
    },
    zones: [],
    selectZone: {
      ZoneID: 0,
      ZoneName: '',
      Locks: []
    },
    selectIndex: 0,
    AddReservation: { name: '', phone: '', duration: '', durationType: 'Minute' },
    selectedLock: { id: 0, no: '0', state: 0 },
    send: null,
    waitForConnection: null
  }),
  created: function () {
    this.GelenAccId = this.$storage.getStorageSync("AccID")
    if(this.GelenAccId == 421) {
      this.AccImgSrc = "http://fit.hopinlock.com/logolar/hurrem.jpg"
    } else if(this.GelenAccId == 441) {
      this.AccImgSrc = "http://fit.hopinlock.com/logolar/resorts.jpg"
    }
    let self = this
    /*
    var i = 0
    this.$socket.onmessage = function (event) {
      let data = JSON.parse(event.data)
      let ResultCode = Number(data.ResultCode)
      if (ResultCode === 1) {
        let Method = data.Method
        if (Method === 'GetLocks') {
          console.log(data.Zones)
          i = 0
          for (i = 0; i < data.Zones.length; i++) {
            if(i === 0) {
              self.selectZone = data.Zones[i]
            }
            self.zones.push(data.Zones[i])
          }
          self.overlay = false
        } else if (Method === 'ChangeLockState') {
          for (var a = 0; a < self.zones.length; a++) {
            for (var j = 0; j < self.zones[a].Locks.length; j++) {
              if (self.zones[a].Locks[j].LockID === data.LockID) {
                self.zones[a].Locks[j].LockState = Number(data.State)
                self.zones[a].Locks[j].LockConnState = 1
                if (self.zones[a].Locks[j].LockJunoState === 2 && self.zones[a].Locks[j].LockState === 3) {
                  self.zones[a].Locks[j].LockJunoState = 0
                  self.zones[a].Locks[j].LockResName = ""
                }
                break;
              }
            }
          }
        } else if (Method === 'SetReservation') {
          for (var a1 = 0; a1 < self.zones.length; a1++) {
            for (var j1 = 0; j1 < self.zones[a1].Locks.length; j1++) {
              if (self.zones[a1].Locks[j1].LockID === data.LockID) {
                self.zones[a1].Locks[j1].LockJunoState = 1
                self.zones[a1].Locks[j1].LockConnState = 1
                self.zones[a1].Locks[j1].LockResID = Number(data.ResID)
                self.zones[a1].Locks[j1].LockResName = data.UserNameSurname
                break;
              }
            }
          }
        } else if (Method === 'CancelReservation') {
          for (var a2 = 0; a2 < self.zones.length; a2++) {
            for (var j2 = 0; j2 < self.zones[a2].Locks.length; j2++) {
              if (self.zones[a2].Locks[j2].LockID === data.LockID) {
                self.zones[a2].Locks[j2].LockJunoState = 2
                self.zones[a2].Locks[j2].LockConnState = 1
                break;
              }
            }
          }
        }
      } else if (ResultCode === 7) {
        self.snackbar.text = data.Description
        self.snackbar.color = 'error'
        self.snackbar.icon = 'fas fa-times-circle'
        self.snackbar.show = true
      } else if (ResultCode === 9) {
        self.$storage.removeStorageSync("AccID")
        self.$storage.removeStorageSync("UserID")
        self.$storage.removeStorageSync("SessionID")
        self.$router.push('/')
      }
    }
    */

    this.send = setInterval(()=>{
      if (state.connected) {
        self.loadLocks()
        clearInterval(self.send)
      }
      /*
      if (self.$socket.readyState === 1) {
        self.loadLocks()
        clearInterval(self.send)
      }
      */
    }, 1000);

    /*

    this.send = function (message, callback) {
      console.log(self.$socket.readyState)
        self.waitForConnection(function () {
          console.log("test")
            self.loadLocks()
            if (typeof callback !== 'undefined') {
              callback();
            }
        }, 1000);
    };

    this.waitForConnection = function (callback, interval) {
      if (self.$socket.readyState === 1) {
          callback();
      } else {
          var that = this;
          // optional: implement backoff for interval here
          setTimeout(function () {
              that.waitForConnection(callback, interval);
          }, interval);
      }
    };
    */
    
  },
  computed: {
    connected() {
      return state.connected
    },
  },
  mounted() {
    // BAD
    socket.on("response", (...args) => {
      console.log(args[0])
      let self = this
      var jsonData = JSON.parse(args[0]);
      let ResultCode = Number(jsonData["ResultCode"])
      if (ResultCode === 1) {
        var method = jsonData["Method"]
        switch(method){
          case "GetLocks":
            for (var i = 0; i < jsonData["Zones"].length; i++) {
              if(i === 0) {
                self.selectZone = jsonData["Zones"][i]
              }
              self.zones.push(jsonData["Zones"][i])
            }
            self.overlay = false
            break;
          case "disconnectLock":
            var lock = jsonData["Lock"]
            for (var a = 0; a < self.zones.length; a++) {
              for (var j = 0; j < self.zones[a].Locks.length; j++) {
                if (self.zones[a].Locks[j].LockID === lock["LockID"]) {
                  self.zones[a].Locks[j].LockConnState = 0
                  break;
                }
              }
            }
            break;
          case "connectLock":
            var lock1 = jsonData["Lock"]
            for (var a1 = 0; a1 < self.zones.length; a1++) {
              for (var j1 = 0; j1 < self.zones[a1].Locks.length; j1++) {
                if (self.zones[a1].Locks[j1].LockID === lock1["LockID"]) {
                  self.zones[a1].Locks[j1].LockConnState = 1
                  break;
                }
              }
            }
            break;
          case "ChangeLockState":
            self.overlay = false
            var lock2 = jsonData["Lock"]
            for (var a2 = 0; a2 < self.zones.length; a2++) {
              for (var j2 = 0; j2 < self.zones[a2].Locks.length; j2++) {
                if (self.zones[a2].Locks[j2].LockID === lock2["LockID"]) {
                  self.zones[a2].Locks[j2].LockState = Number(jsonData["State"])
                  //self.zones[a2].Locks[j2].LockConnState = 1
                  if (self.zones[a2].Locks[j2].LockJunoState === 2 && self.zones[a2].Locks[j2].LockState === 3) {
                    self.zones[a2].Locks[j2].LockJunoState = 0
                    self.zones[a2].Locks[j2].LockResName = ""
                  }
                  break;
                }
              }
            }
            break;
          case "SetReservation":
            for (var a3 = 0; a3 < self.zones.length; a3++) {
              for (var j3 = 0; j3 < self.zones[a3].Locks.length; j3++) {
                if (self.zones[a3].Locks[j3].LockID === jsonData["LockID"]) {
                  self.zones[a3].Locks[j3].LockJunoState = 1
                  self.zones[a3].Locks[j3].LockConnState = 1
                  self.zones[a3].Locks[j3].LockResID = Number(jsonData["ResID"])
                  self.zones[a3].Locks[j3].LockResName = jsonData["UserNameSurname"]
                  self.overlay = false
                  break;
                }
              }
            }
            break;
          case "CancelReservation":
            for (var a4 = 0; a4 < self.zones.length; a4++) {
              for (var j4 = 0; j4 < self.zones[a4].Locks.length; j4++) {
                if (self.zones[a4].Locks[j4].LockID === jsonData["LockID"]) {
                  self.zones[a4].Locks[j4].LockJunoState = 2
                  self.zones[a4].Locks[j4].LockConnState = 1
                  self.overlay = false
                  break;
                }
              }
            }
            break;
          default:
            break;
        }
      } else if (ResultCode === 7) {
        self.overlay = false
        self.snackbar.text = jsonData["Description"]
        self.snackbar.color = 'error'
        self.snackbar.icon = 'fas fa-times-circle'
        self.snackbar.show = true
      } else if (ResultCode === 9) {
        self.$storage.removeStorageSync("AccID")
        self.$storage.removeStorageSync("UserID")
        self.$storage.removeStorageSync("SessionID")
        self.$router.push('/')
      }
    });
  }
}
</script>

<style type="text/css" scoped>
  .selectToggle {
    color: white;
  }
</style>
