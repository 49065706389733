<template>
  <v-app id="login" :style="{background: theme.colors.primary}">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="snackbar.show"
      right
      top
      timeout="6000"
      :color="snackbar.color"
      style="{top: 0}">
      <v-icon color="white">{{snackbar.icon}}</v-icon> {{ snackbar.text }}
    </v-snackbar>
    <v-content class="content">
      <v-container
        class="fill-height"
        fluid>
        <v-row
          align="center"
          justify="center">
          <v-col cols="12" sm="8" md="6">
            <v-card class="elevation-12 grey lighten-4 pa-4">
              <h2 class="primary--text text-center headline mt-2" :style="{color: theme.colors.primary}">hopinlock Login Screen</h2>
              <v-card-text>
                <v-form
                  ref="formLogin"
                  lazy-validation
                  v-model="valid">
                  <v-text-field
                    v-model="formData.accno"
                    label="Account No"
                    prepend-inner-icon="mdi-account"
                    :rules="[rules.required]"
                    outlined
                    clearable
                    @keypress="onlyNumber($event)"
                    v-on:keyup.enter="login"></v-text-field>
                  <v-text-field
                    v-model="formData.username"
                    label="Username"
                    prepend-inner-icon="mdi-account"
                    :rules="[rules.required]"
                    outlined
                    clearable
                    v-on:keyup.enter="login"></v-text-field>
                  <v-text-field
                    v-model="formData.password"
                    label="Password"
                    hint="At least 8 characters"
                    prepend-inner-icon="mdi-lock"
                    :rules="[rules.required, rules.min]"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPass ? 'text' : 'password'"
                    counter
                    outlined
                    @click:append="showPass = !showPass"
                    v-on:keyup.enter="login"></v-text-field>
                </v-form>
              </v-card-text>
              <v-divider class="ma-0"></v-divider>
              <v-card-actions>
                <v-spacer/>
                <v-btn
                  color="primary"
                  text
                  :disabled="!valid"
                  @click="login">LOGIN</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>


<script>
import axios from 'axios'

export default {
  name: 'login',
  data: () => ({
    overlay: false,
    valid: true,
    rules: {
      required: value => !!value || 'This field is required.',
      min: v => v.length >= 8 || 'Min 8 characters.'
    },
    showPass: false,
    formData: {
      accno: '',
      username: '',
      password: ''
    },
    snackbar: {
      show: false,
      text: '',
      color: 'success',
      icon: 'mdi-check-circle'
    }
  }),
  methods: {
    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if (keyCode < 48 || keyCode > 57) { // 46 is dot
        $event.preventDefault()
      }
    },
    login () {
      if (this.$refs.formLogin.validate()) {
        this.overlay = true
        const accno = this.formData.accno
        const username = this.formData.username
        const pass = this.formData.password
        var result = false
        var UserID = 0
        var AccID = 0
        var SessionID = ''
        axios.get('https://hopinlock.com/hopinlock/api/login.php', {
          params: {
            UserName: username,
            Password: pass,
            AccID: accno,
            FitType: 1
          }
        })
          .then(function (response) {
            console.log(response.data)
            if (response.data.Data.ResultCode === 0) {
              // İşlem başarılı
              UserID = response.data.Data.UserID
              SessionID = response.data.Data.SessionID
              AccID = response.data.Data.AccountID
              result = true
            } else {
              // Hatalı bilgiler
              result = false
            }
          })
          .catch(function (error) {
            console.log(error)
          })
          .finally(() => {
            this.overlay = false
            if (result) {
              this.$storage.setStorageSync("AccID", AccID)
              this.$storage.setStorageSync("UserID", UserID)
              this.$storage.setStorageSync("SessionID", SessionID)
              this.$router.push('home')
            } else {
              this.snackbar.text = 'Kullanıcı no, kullanıcı adı veya şifre hatali'
              this.snackbar.color = 'error'
              this.snackbar.icon = 'mdi-alert-circle'
              this.snackbar.show = true
            }
          })
      }
    }
  },
  created: function () {
    
  },
  computed:{
    theme(){
      return this.$vuetify.theme.themes["myCustomLightTheme"]
    }
  }
}
</script>


<style type="text/css" scoped="">
  .content {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    max-width: 100%;
    -webkit-transition: .2s cubic-bezier(.4,0,.2,1);
    transition: .2s cubic-bezier(.4,0,.2,1);
  }
</style>