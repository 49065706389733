import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

//import VueSocketIO from 'vue-3-socket.io';

import Vue3Storage from "vue3-storage";
// import VueNativeSock from 'vue-native-websocket-vue3'
// import store from './store'
/*
import {
  SOCKET_ONOPEN,
  SOCKET_ONCLOSE,
  SOCKET_ONERROR,
  SOCKET_ONMESSAGE,
  SOCKET_RECONNECT,
  SOCKET_RECONNECT_ERROR
} from './mutation-types'
*/

loadFonts()
/*
const mutations = {
  SOCKET_ONOPEN,
  SOCKET_ONCLOSE,
  SOCKET_ONERROR,
  SOCKET_ONMESSAGE,
  SOCKET_RECONNECT,
  SOCKET_RECONNECT_ERROR
}
*/

createApp(App)
  .use(vuetify)
  .use(router)
  .use(Vue3Storage)
  .use(VueSweetalert2)
  .component('vue-phone-number-input', VuePhoneNumberInput)
  /*
  .use(VueNativeSock, 'wss://www.hopino.com:12346/Juno', {
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 3000,
    store: store,
    mutations: mutations
  })
  */
  /*
  .use(new VueSocketIO({
    debug: true,
    connection: '/socket',
    transports: ["websocket"],
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
    },
    options: { } //Optional options
  }))
  */
  .mount('#app')
