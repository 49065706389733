<template>
  <div class="topbar">
    <v-app-bar
      app
      fixed
      clipped-left
      color="#55334E">
      <!--
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        color=white />
      -->
      <v-img
        class="mx-2"
        src="@/assets/online_logo.png"
        max-height="40"
        max-width="110"
        @click="goHomePage"
        contain
      ></v-img>
      <v-spacer></v-spacer>
      <v-menu
        left
        bottom
        offsetY
        open-on-hover>
        <template v-slot:activator="{ props }">
          <v-btn
            justify-end
            icon
            text
            exact
            width="auto" height="auto"
            class="mr-lg-1 mr-md-1 mr-xs-1"
            v-bind="props">
            <v-avatar
              size="38">
              <v-img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"/>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list
            color="#EF7D09">
            <v-list-item>
              <v-list-item-action>
                <v-btn
                  small
                  block
                  prepend-icon="mdi-cog"
                  rounded="lg" size="x-large"
                  color="info"
                  class="mb-2"
                  link
                  router
                  to="/settings"
                  exact>Settings
                </v-btn>
              </v-list-item-action>

              <v-list-item-action>
                <v-btn
                  small
                  block
                  prepend-icon="mdi-logout"
                  rounded="lg" size="x-large"
                  color="error"
                  @click="logout">Logout
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'TopBar',
  data: () => ({
    drawer: null,
    showBadge: true,
    languages: [
      {
        name: 'English',
        code: 'en'
      },
      {
        name: 'Türkçe',
        code: 'tr'
      }
    ]
  }),
  methods: {
    goHomePage () {
      this.$router.push('/home')
    },
    logout () {
      this.$storage.removeStorageSync("AccID")
      this.$storage.removeStorageSync("UserID")
      this.$storage.removeStorageSync("SessionID")
      this.$router.push('/')
    }
  }
}
</script>
